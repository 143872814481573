import { useEffect, useState } from 'react';
import fetchGql from '../util/fetchGql';

const CACHE = {};

export default function useFetch (query, variables = {}, cacheKey = null, skip = false) {
	const [prev, setPrev] = useState(null);
	const [state, _setState] = useState({ data: null, loading: false, error: null });
	const setState = next => {
		_setState(prev => ({
			...prev,
			...next,
		}));
	};

	useEffect(() => {
		if (!process.browser || skip) return;

		const next = JSON.stringify({query, variables});
		if (next === prev) return;
		setPrev(next);

		if (cacheKey && CACHE.hasOwnProperty(cacheKey)) {
			setState({ loading: false, data: CACHE[cacheKey], error: null });
			return;
		}

		(async () => {
			setState({ loading: true });
			try {
				const data = await fetchGql(query, variables);
				setState({ loading: false, data, error: null });
				cacheKey && (CACHE[cacheKey] = data);
			} catch (error) {
				setState({ loading: false, data: null, error });
			}
		})();
	}, [query, variables, skip]);

	return state;
}
